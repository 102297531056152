<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Control de Habitaciones</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>

              <b-col sm="12" md="8"></b-col>
              <b-col md="2" sm="6">
                  <b-button class="form-control" v-b-modal.modal-1>Leyenda</b-button>
              </b-col>
              <b-col md="2" sm="6">
                  <b-button class="form-control" variant="primary" @click="mLoadListRoomControl">Actualizar <b-icon icon="refresh"></b-icon></b-button>
              </b-col>
            </b-row>





            <b-row class="mt-4">
              <b-col sm="6" md="4" lg="4"  xl="4" v-for="(item,index) in room_control_rc" :key="index">
         
                  <b-card-body :class="BackgroundColor(item.state)+' '+'border'">
                    <b-card-text class="text-white text-center">
                        {{State(item.state)}}
                    </b-card-text>
                    <b-card-title class="text-center text-white">{{item.room_number}} ( {{item.type_room_name}} )</b-card-title>
                    <b-card-text class="text-white text-left" style="height: 30px;">
                       <small> Cliente : {{ item.client_name }}</small>
                    </b-card-text>
                  </b-card-body>

                  <div :class="BackgroundColor(item.state)">

                        <b-row  class="no-gutters border">
                          <b-col sm="12" md="4" lg="4"  xl="4" class="p-1">
                            <button :disabled="item.state != 1" type="button" title="Habitación" @click="ShowModalRoom(item.id_room,item.id_room_control)" class="btn btn-warning form-control text-right mb-1"><small>{{ item.room_price }}</small> <i class="fas fa-bed"></i></button>
                          </b-col>
                          <b-col sm="12" md="4" lg="4"  xl="4" class="p-1">
                            <button :disabled="item.state != 1" type="button" title="Frigobar" @click="ShowModalMinibar(item.id_room_control)" class="btn btn-warning form-control text-right mb-1"><small>{{ item.minibar_price }}</small> <i class="fas fa-dolly-flatbed"></i></button>
                          </b-col>
                          <b-col sm="12" md="4" lg="4"  xl="4" class="p-1">
                            <button :disabled="item.state != 1" type="button" title="Productos" @click="ShowModalConsumer(item.id_room_control)" class="btn btn-warning form-control text-right mb-1"><small>{{ item.consumer_price }}</small><i class="fas fa-cart-plus"></i></button>
                          </b-col>
                        </b-row>

                         <b-row  v-if="item.state == 0" class="no-gutters border">
                          <b-col sm="12" class="p-1">
                            <b-button class="form-control" variant="primary" title="Nuevo" @click="ShowModalNewRoomControl(item.id_room)" ><i class="far fa-file"></i></b-button>
                          </b-col>
                        </b-row>


                        <b-row  v-if="item.state == 1" class="no-gutters border">
                          <b-col sm="3"  class="p-1">
                            <b-button class="form-control" title="Ver Detalle" variant="info" @click="ShowModalRoomControlDetail(item.id_room,item.id_room_control)" ><i class="fas fa-eye"></i></b-button>
                          </b-col>
                          <b-col sm="3"  class="p-1">
                            <b-button class="form-control" title="Pagos a Cuenta" variant="warning" @click="ShowModalPayment(item.id_room_control)" ><i class="fas fa-money-bill-alt"></i></b-button>
                          </b-col>
                          <b-col sm="3"  class="p-1">
                            <b-button class="form-control" title="Facturar" variant="success" @click="ShowModalPayment(item.id_room_control)" ><i class="fas fa-money-bill-alt"></i></b-button>
                          </b-col>
                          <b-col sm="3"  class="p-1">
                            <b-button class="form-control" title="Anular" variant="danger" @click="Cancel(item.id_room_control)" ><i class="fas fa-ban"></i></b-button>
                          </b-col>
                        </b-row>

                        <b-row  v-if="item.state == 2 || item.state == 3" class="no-gutters border">
                          <b-col sm="12"  class="p-1">
                            <b-button class="form-control" variant="info" @click="ShowModalRoomControlDetail(item.id_room,item.id_room_control)" ><i class="fas fa-eye"></i></b-button>
                          </b-col>
                        </b-row>

                  </div>

                  <b-row class="no-gutters text-white bg-primary mb-2">
                    <b-col sm="4" md="4" lg="4"  xl="4" class="text-center border p-1">
                      <strong>{{ item.beds }}</strong> <br>
                      <small>Cama(s)</small>
                    </b-col>
                    <b-col sm="4" md="4" lg="4"  xl="4" class="text-center border p-1">
                      <strong>{{ item.maximum_guest }}</strong> <br>
                      <small>Húespedes Max.</small>
                    </b-col>
                    <b-col sm="4" md="4" lg="4"  xl="4" class="text-center border p-1">
                      <strong>S/.  {{ item.price }}</strong> <br>
                      <small>Precio</small>
                    </b-col>
                  </b-row>
                   

              </b-col>
            </b-row>




    
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <b-modal size="sm" id="modal-1" title="Estado de Habitaciones" hide-footer>
      <div class="table-responsive">
          <table class="table table-hover table-bordered w-100">
            <thead>
              <tr>
                <td class="bg-success">Libre</td>
              </tr>
              <tr>
                <td class="bg-danger">Ocupado</td>
              </tr>
              <tr>
                <td class="bg-warning">En Espera de Mantenimiento</td>
              </tr>
              <tr>
                <td class="bg-info">En Mantenimiento</td>
              </tr>
            </thead>
          </table>
      </div>
    </b-modal>

    <ModalMinibar/>
    <ModalConsumer/>
    <ModalRoom/>
    <ModalNewRoomControl/>
    <ModalPayment/>
    <ModalRoomControlDetail/>
  </div>
</template>
<style scoped>
  td{
    vertical-align: middle;
  }
  .card-footer {
    padding: 0rem 0rem;
  }
</style>>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";

// MODULES
import ModalMinibar from './components/ModalMinibar'
import ModalConsumer from './components/ModalConsumer'
import ModalRoom from './components/ModalRoom'
import ModalNewRoomControl from './components/ModalNewRoomControl'
import ModalPayment from './components/ModalPayment'
import ModalRoomControlDetail from './components/ModalRoomControlDetail'


export default {
  name: "ControlHabitacionList",
  components:{
      ModalMinibar,
      ModalConsumer,
      ModalRoom,
      ModalNewRoomControl,
      ModalPayment,
      ModalRoomControlDetail
  },
  data() {
    return {
      module:'RoomControl',
      search:'',
    };
  },
  mounted() {
    this.mLoadListRoomControl();
   
  },
  methods: {

    ChangeSearch,
    ShowModalMinibar,
    ShowModalConsumer,
    ShowModalRoom,
    ShowModalNewRoomControl,
    ShowModalPayment,
    ShowModalRoomControlDetail,

    Cancel,
    Maintenance,
    BreakFree,
    
    BackgroundColor,
    State,

    ...mapActions('RoomControl',['mLoadListRoomControl']),
    ...mapActions('RoomControl',['mShowModalMinibar']),
    ...mapMutations('RoomControl',['mListRoomControl','mSetSearch'])
  },

  computed: {
    ...mapState(["url_base"]),
    ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ChangeSearch() {
  this.mSetSearch(this.search);
}
function State(state) {
  let TextState = 'Libre';
   switch (state) {
    case 1: TextState = 'Ocupado'; break;
    case 2: TextState = 'En Espera de Mantenimiento'; break;
    case 3: TextState = 'En Mantenimiento'; break;
    // case 4: TextState = 'bg-warning'; break;
    // case 5: TextState = 'bg-warning'; break;
    // case 6: TextState = 'bg-warning'; break;
    // case 7: TextState = 'bg-info'; break;
    default: break;
  }

  return TextState;
  
}
function BackgroundColor(state) {
    // 0 libre 
    // 1 ocupado 
    // 2 libre para mantenimiento 
    // 3 en mantenimiento 
    // 4 finalizado 
    // 5 reservado 
    // 6 anulado 
    // 9 eliminado 
  let BackgroundColor = 'bg-success';
  switch (state) {
    case 1: BackgroundColor = 'bg-danger'; break;
    case 2: BackgroundColor = 'bg-warning'; break;
    case 3: BackgroundColor = 'bg-info'; break;
    // case 4: BackgroundColor = 'bg-warning'; break;
    // case 5: BackgroundColor = 'bg-warning'; break;
    // case 6: BackgroundColor = 'bg-warning'; break;
    // case 7: BackgroundColor = 'bg-info'; break;
    default: break;
  }

  return BackgroundColor;
  
}
// abrir modal frigobar
function ShowModalMinibar(id_room_control) {
  EventBus.$emit('ModalMinibarShow',id_room_control);
}
// abrir modal consumer
function ShowModalConsumer(id_room_control) {
  EventBus.$emit('ModalConsumerShow',id_room_control);
}
// abrir modal consumer
function ShowModalRoom(id_room,id_room_control) {
  // let data = { id_room:id_room,id_room_control:id_room_control };
  EventBus.$emit('ModalRoomShow',id_room,id_room_control);
}
// abrir modal nuevo control habitacion
function ShowModalNewRoomControl(id_room) {
  EventBus.$emit('ModalNewRoomControlShow',id_room);
}
// abrir modal nuevo control habitacion
function ShowModalPayment(id_room_control) {
  EventBus.$emit('ModalPaymentShow',id_room_control);
}

function ShowModalRoomControlDetail(id_room,id_room_control) {
  EventBus.$emit('ModalRoomControlDetailShow',id_room,id_room_control);
}

function Cancel(id_room_control) {

  let me = this;
  let url = this.url_base + "room-control/cancel/" + id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 4,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: 'Se anulo la habitación', timer: 2000,})
          me.mLoadListRoomControl();
      }else if (response.data.status == 400){
        Swal.fire({ icon: 'error', text: 'No se puede anular la habitación seleccionada', timer: 2000,})
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurido un error', timer: 2000,})
      }
    })

}

function Maintenance(id_room_control) {
  let me = this;
  let state = 5;
  let url = this.url_base + "room-control/free-room/" + id_room_control+'/'+state;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
            me.mLoadListRoomControl();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function BreakFree(id_room_control) {
  let me = this;
  let state = 6;
  let url = this.url_base + "room-control/free-room/" + id_room_control+'/'+state;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 3,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
            me.mLoadListRoomControl();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}
// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
