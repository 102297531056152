<template>
  <div>
    <b-modal size="xl" id="modal_room" hide-footer v-model="modal_room" class="w-100" title="DATOS DE ESTADIA">
      <b-row>
        <b-col md="6">
          <CCard>
            <CCardHeader>
              <strong>Información de la habitación</strong>
            </CCardHeader>
            <CCardBody>
              <b-list-group>
                <b-list-group-item>
                  <strong>Tipo Habitación :</strong>
                  <span class="pl-2">{{room.type_room_name}}</span>
                </b-list-group-item>
                <b-list-group-item>
                  <strong>Nro Habitación :</strong>
                  <span class="pl-2">{{room.room_number}}</span>
                </b-list-group-item>
                <b-list-group-item>
                  <strong>Precio :</strong>
                  <span class="pl-2">{{room.price}}</span>
                </b-list-group-item>
                <b-list-group-item>
                  <strong>Descripción :</strong>
                  <span class="pl-2">{{room.description}}</span>
                </b-list-group-item>
                <b-list-group-item class="text-center">
                  <img class="img-fluid text-center" :src="url_base + room.photo" alt="">
                </b-list-group-item>
              
              </b-list-group>
            </CCardBody>
          </CCard>
        </b-col>


        <b-col md="6">
          <CCard>
            <CCardHeader>
              <strong>Información de la Estadia</strong>
            </CCardHeader>
            <CCardBody>
              <b-form @submit.prevent="EditRoomControl">
              <b-row>
         
                <b-col sm="12" md="12">
                  <b-form-group>
                    <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                    <v-select placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="search" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>
          
                <b-col sm="4" md="4">
                  <b-form-group>
                    <label class="control-label">Precio :</label>
                    <input type="number" @change="CalculateEndDate" v-model="room_control.price" step="any" class="form-control text-right">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="4">
                  <b-form-group>
                    <label class="control-label">Fecha Inicio:</label>
                    <input type="text" @change="CalculateEndDate" readonly v-model="room_control.date" class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="4">
                  <b-form-group>
                    <label class="control-label">Hora Inicio:</label>
                    <input type="time" step="1" @change="CalculateEndDate" readonly v-model="room_control.hour" class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col sm="3" md="4">
                  <b-form-group>
                    <label class="control-label">Estadia:</label>
                    <input type="number" @change="CalculateEndDate" v-model="room_control.quantity" class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col  sm="5" md="4">
                  <b-form-group>
                    <label class="control-label">Fecha y Hora Final:</label>
                    <input type="text" v-model="room_control.final_date" readonly class="form-control text-center">
                  </b-form-group>
                </b-col>

                <b-col sm="4" md="4">
                  <b-form-group>
                    <label class="control-label">Precio x Estadia:</label>
                    <input type="number" v-model="room_control.room_price" readonly step="any" class="form-control text-right">
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="12">
                  <b-form-group>
                    <label class="control-label">Observación:</label>
                    <textarea  rows="2" v-model="room_control.observation" class="form-control"></textarea>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <button type="submit" class="btn btn-info form-control">GUARDAR</button>
                  </b-form-group>
                </b-col>

         
              </b-row>
            </b-form>
            </CCardBody>
          </CCard>
        </b-col>

      </b-row>
    </b-modal>



  


    <!-- Modal Cliente -->
    <!-- <ModalClientRoom/> -->
  </div>
</template>


<style >


</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";


// import ModalClientRoom from './../../components/ModalClient'

export default {
  name: "ModalRoomControl",
  components:{
    // ModalClientRoom,
    vSelect
  },
  data() {
    return {

        module: 'RoomControl',
        role:3,

        modal_room:false,
        id_room: 0,
        room: {
          id_room:0,
          id_type_room:0,
          room_number:'',
          photo:'',
          price:0,
          description:'',
          type_room_name:'',
        },
        room_control:{
          client_name:'',
          client_document_number:'',
          id_room:0,
          id_type_room:0,
          id_user:0,
          id_client:'',
          date:'',
          hour:'',
          start_date:'',
          final_date:'',
          price:'',
          quantity:'',
          room_price:'',
          observation:'',
        },

        clients:[],
        client:null,

        errors:{
          id_client:false,
          start_date:false,
          final_date:false,
          price:false,
          quantity:false,
          room_price:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalRoomShow', (id_room,id_room_control) => {
        this.id_room_control = id_room_control;
        this.id_room = id_room;
        this.ViewRoom();
        this.ViewRoomControl();
        this.modal_room = true;
      });




  },
  methods: {
      search(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.clients = response.data;
                loading(false);
          })
        }
      },
      ViewRoom,
      ViewRoomControl,
      CalculateEndDate,
      EditRoomControl,
      Validate,
      ShowModalClient,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};

//Ver habitacion
function ViewRoom() {
 
  let me = this;
  let url = this.url_base + "room/view/" + this.id_room;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room.id_room = response.data.result.id_room;
        me.room.id_type_room = response.data.result.id_type_room;
        me.room.room_number = response.data.result.room_number;
        me.room.photo = response.data.result.photo;
        me.room.price = response.data.result.price;
        me.room.description = response.data.result.description;
        me.room.type_room_name = response.data.result.type_room_name;

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })


}

function ViewRoomControl() {
  let me = this;
  let url = this.url_base + "room-control/view/" + this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.client = {id : response.data.result.id_client, full_name: response.data.result.client_name + ' - ' + response.data.result.client_document_number};

        me.room_control.client_name = response.data.result.client_name;
        me.room_control.client_document_number = response.data.result.client_document_number;
        me.room_control.id_client = response.data.result.id_client;
        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_room_control = response.data.result.id_room_control;
        me.room_control.id_user = response.data.result.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.quantity = response.data.result.quantity;
        me.room_control.start_date = response.data.result.start_date;
        me.room_control.final_date = response.data.result.final_date;
        me.room_control.date = moment(response.data.result.date).local().format("YYYY-MM-DD");
        me.room_control.hour = response.data.result.hour;
        me.room_control.room_price = response.data.result.room_price;
    
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}

//Calcular fecha final
function CalculateEndDate() {
  
  
  this.room_control.final_date = moment(this.room_control.start_date, "YYYY-MM-DD HH:mm:ss").add(parseInt(this.room_control.quantity) , 'quantity').local().format("YYYY-MM-DD HH:mm:ss");
  this.room_control.room_price = parseFloat(this.room_control.quantity) * parseFloat(this.room_control.price); 

  this.room_control.price = parseFloat(this.room_control.price).toFixed(2);
  this.room_control.room_price = this.room_control.room_price.toFixed(2);

}
//modal new cliente
function ShowModalClient() {
  
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function EditRoomControl() {

  this.Validate();
  if (this.validate) {
    return false;
  }

  this.room_control.id_client = this.client.id;
  let me = this;
  let url = this.url_base + "room-control/edit";
  let data = this.room_control;

  

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "Se ha actualizado la estadia", "success");
      } else if  (response.data.status == 409) {
        me.mLoadListRoomControl();
        Swal.fire("Sistema", "La habitacion seleccionado ya no se encuentra disponible", "error");
        me.modal_room = false;
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {

  this.validate = false;
  this.errors.id_client = this.client == null || this.client == {} ? true : false;
  this.errors.final_date = this.room_control.start_date.length == 0 ? true : false;
  this.errors.price = this.room_control.start_date.length == 0 ? true : false;
  this.errors.quantity = this.room_control.start_date.length == 0 ? true : false;
  this.errors.room_price = this.room_control.start_date.length == 0 ? true : false;

  if (this.errors.id_client) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.final_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.quantity) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.room_price) { this.validate = true; return false;}else{ this.validate = false; }

}


</script>