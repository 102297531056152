<template>
  <div>
    <b-modal size="xl" id="modal_payment" hide-footer v-model="modal_payment" class="w-100" title="PAGO">
      

            <b-form @submit.prevent="AddPayment">
              <b-row>
         
               

                <b-col sm="7" md="4">
                  <b-form-group>
                    <label class="control-label">Comprobante: </label>
                    <b-form-select v-model="payment.id_serie" :options="series"></b-form-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="5" md="3">
                  <b-form-group>
                    <label class="control-label">Fecha Emision: </label>
                    <b-form-input class="text-center" readonly v-model="payment.broadcast_date" type="date"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="3">
                  <b-form-group>
                    <label class="control-label">Forma de Pago: </label>
                    <b-form-select v-model="payment.way_to_pay" :options="way_to_pay"></b-form-select>
                    <small v-if="errors.way_to_pay" class="form-text text-danger" >Seleccione ua forma de pago</small>
                  </b-form-group>
                </b-col>

                <b-col sm="6" md="2">
                  <b-form-group>
                    <label class="control-label">Moneda: </label>
                    <b-form-select v-model="payment.coin" :options="coins"></b-form-select>
                    <small v-if="errors.coin" class="form-text text-danger" >Seleccione una moneda</small>
                  </b-form-group>
                </b-col>

                 <b-col sm="12" md="5">
                  <b-form-group>
                    <!-- <label class="control-label">Cliente: </label>
                    <input class="form-control" :value="room_control.client_name + ' - ' + room_control.client_document_number" readonly /> -->
                    <label class="control-label">Cliente: <span class="badge badge-primary link" @click="ShowModalClient">NUEVO</span></label>
                    <v-select placeholder="Seleccione un cliente" inputId="id" class="w-100" :filterable="false" label="full_name" v-model="client" @search="search" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="7">
                  <b-form-group>
                    <label class="control-label">Observación: </label>
                    <b-form-input v-model="payment.observation" type="text"></b-form-input>                    
                  </b-form-group>
                </b-col>


                <b-col md="12">
                    <div class="table-responsive mt-3">
                      <table class="table table-striped table-hover table-bordered table-lg mt-lg mb-0">
                        <thead>
                          <tr>
                            <th width="5%" class="text-center">#</th>
                            <th width="10%" class="text-center">Codigo</th>
                            <th width="52%" class="text-center">Nombre</th>
                            <th width="7%" class="text-center">Cantidad</th>
                            <th width="10%" class="text-center">P. Unit</th>
                            <th width="10%" class="text-center">P. Total</th>
                          </tr>
                        </thead>
                        <tbody v-for="(item, index) in room_control_detail" :key="index">
                          <tr>
                              <td class="text-center">{{ index + 1 }}</td>
                              <td class="text-left">{{ item.code }}</td>
                              <td class="text-left">{{ item.name }}</td>
                              <td class="text-center">{{ item.quantity }}</td>
                              <td class="text-right">{{ item.unit_price }}</td>
                              <td class="text-right">{{ item.total_price }}</td>
                          </tr>
                              
                        </tbody>
                      </table>
                    </div>
                </b-col>
          
<!-- {{room_control_detail}} -->


                <b-col class="mt-4" md="12">

                </b-col>


                <b-col md="12">

                    <b-row>

                      <b-col md="12">
                        <b-form-group>
                          <b-form-input readonly v-model="payment.number_to_letters" type="text"></b-form-input>
                        </b-form-group>
                     </b-col>

                    </b-row>


                    
             
                </b-col>

                <b-col sm="6" md="4">
                  
                  <b-form-group label-cols-sm="6"  label="Ope. Gravadas :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.taxed_operation" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Ope. Inafectas :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.exonerated_operation" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Ope. Exoneradas :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.unaffected_operation" readonly ></b-form-input>
                  </b-form-group>


                </b-col>


                <b-col sm="6" md="4">
                  
                  <b-form-group label-cols-sm="6"  label="Subtotal :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.subtotal" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Igv (18%) :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.igv" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Total :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.total" readonly ></b-form-input>
                  </b-form-group>

                </b-col>

                <b-col sm="12" md="4">
                  
                  <b-form-group label-cols-sm="6"  label="Efectivo :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" v-model="payment.cash_payment" readonly ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Tarjeta :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" @change="PaymentMethod('card')" v-model="payment.card_payment" ></b-form-input>
                  </b-form-group>

                  <b-form-group label-cols-sm="6"  label="Via Web :" class="text-right">
                    <b-form-input type="number" step="any" class="text-right" @change="PaymentMethod('web')" v-model="payment.web_way_payment" ></b-form-input>
                  </b-form-group>

                </b-col>


                <b-col md="12">
                  <b-form-group>
                    <button type="submit" class="btn btn-info form-control">GUARDAR</button>
                  </b-form-group>
                </b-col>

         
              </b-row>
            </b-form>
      
    </b-modal>



  


    <!-- Modal Cliente -->
    <!-- <ModalClient/> -->
  </div>
</template>


<style >


</style>
 
<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import converter from "@/assets/js/NumberToLetters";

// import ModalClient from './ModalClient'

export default {
  name: "ModalRoomControl",
  components:{
    // ModalClient,
    vSelect
  },
  data() {
    return {

        module: 'RoomControl',
        role:3,
        modal_payment:false,


        payment: {
          client:'',
          way_to_pay:'01-008',

          id_payment:'',
          id_room_control:'',
          id_client:'',
          id_user:'',
          id_serie:'',
          voucher_type:'',
          serie:'',
          number:'',
          broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
          broadcast_time:moment(new Date()).local().format("HH-mm-ss"),
          expiration_date:moment(new Date()).local().format("YYYY-MM-DD"),
          coin:'PEN',
          payment_type:'',
          payment_method:'',
          payment_deadline:'',
          observation:'',
          modified_voucher_type:'',
          modified_serie:'',
          modified_number:'',
          modified_broadcast_date:'',
          modified_reason:'',
          modified_support:'',
          sunat_message:'',
          hash_cpe:'',
          hash_cdr:'',
          taxed_operation: (0).toFixed(2),
          exonerated_operation:(0).toFixed(2),
          unaffected_operation:(0).toFixed(2),
          discount:(0).toFixed(2),
          subtotal:(0).toFixed(2),
          igv:(0).toFixed(2),
          total:(0).toFixed(2),
          cash_payment:(0).toFixed(2),
          card_payment:(0).toFixed(2),
          web_way_payment:(0).toFixed(2),
          state:'1',
          number_to_letters : '',

        },
        
        room_control: {
          id_room_control:0
        },

        room_control_detail:[],

        series:[],
        clients:[],
        client:null,
        way_to_pay:[
            {value:'01-008',text:'Contado - Efectivo'},
            {value:'01-001',text:'Contado - Depósito en Cuenta'},
            {value:'01-002',text:'Contado - Giro'},
            {value:'01-003',text:'Contado - Transferencia de Fondos'},
            {value:'01-004',text:'Contado - Orden de Pago'},
            {value:'01-005',text:'Contado - Tarjeta de Débito'},
            // {value:'03-7',text:'Credito - 7 Dias'},
            // {value:'03-15',text:'Credito - 15 Dias'},
            // {value:'03-30',text:'Credito - 30 Dias'},
            // {value:'03-45',text:'Credito - 45 Dias'},
            // {value:'03-60',text:'Credito - 60 Dias'},
            // {value:'03-75',text:'Credito - 75 Dias'},
        ],
        coins:[
          {value: 'PEN', text:'PEN'},
          {value: 'USD', text:'USD'},
          {value: 'CLP', text:'CLP'},
        ],

        errors:{
          id_client:false,
          start_date:false,
          final_date:false,
          price:false,
          quantity:false,
          room_price:false,
        },
        validate: false,
    };
  },
  mounted () {
      EventBus.$on('ModalPaymentShow', (id_room_control) => {
        this.id_room_control = id_room_control;
        this.ViewRoomControl();
        this.modal_payment = true;
        this.GetSeries();
        this.ListRoomControlDetailPayment();
      });
      

  },
  methods: {
      search(search, loading) {
        let me = this;
        let url = this.url_base + "search-clients/" + search;
        if (search !== "") {
          loading(true);
          axios({
            method: "GET",
            url: url,
          }).then(function (response) {
                me.clients = response.data;
                loading(false);
          })
        }
      },

      GetSeries,
      ListRoomControlDetailPayment,
      TotalPrices,
      PaymentMethod,
      ShowModalClient,

      ViewRoomControl,
      AddPayment,
      Validate,
      ShowModalClient,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
  },
  computed: {
    ...mapState(["url_base"]),
    // ...mapState('RoomControl',['room_control_rc']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.id_user;
    },

  },
};



function GetSeries() {
  let me = this;
  let url = this.url_base + "series-sale";
  this.series = [];
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        let selected = true
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.series.push({value: element.id_serie, text: element.name + ' ' + element.serie +'-'+element.number});
          if (selected && element.code == '01') {
            me.payment.id_serie = element.id_serie;
            selected = false;
          }
          
        }

      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}


function ListRoomControlDetailPayment() {

  let me = this;
  let url = this.url_base + "room-control/list-detail-payment/"+this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room_control_detail = response.data.result;
        me.TotalPrices();
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function TotalPrices() {
  let detail = this.room_control_detail;
  this.payment.taxed_operation = 0;
  this.payment.exonerated_operation = 0;
  this.payment.unaffected_operation = 0;
  this.payment.discount = 0;
  this.payment.subtotal = 0;
  this.payment.igv = 0;
  this.payment.total = 0;

  for (let index = 0; index < detail.length; index++) {
    if (detail[index].igv == '10') {
      this.payment.taxed_operation += parseFloat(detail[index].total_price);
    }
    if (detail[index].igv == '20') {
      this.payment.exonerated_operation += parseFloat(detail[index].total_price);
    }
    if (detail[index].igv == '30') {
      this.payment.unaffected_operation += parseFloat(detail[index].total_price);
    }
  }

  this.payment.total = (this.payment.taxed_operation + this.payment.exonerated_operation + this.payment.unaffected_operation);
  this.payment.igv = this.payment.taxed_operation - (this.payment.taxed_operation / 1.18);
  this.payment.subtotal = (this.payment.total  - this.payment.igv);
  

  this.payment.taxed_operation = this.payment.taxed_operation.toFixed(2);
  this.payment.exonerated_operation = this.payment.exonerated_operation.toFixed(2);
  this.payment.unaffected_operation = this.payment.unaffected_operation.toFixed(2);
  this.payment.discount = this.payment.discount.toFixed(2);
  this.payment.subtotal = this.payment.subtotal.toFixed(2);
  this.payment.igv = this.payment.igv.toFixed(2);
  this.payment.total = this.payment.total.toFixed(2);

  this.payment.number_to_letters =  'SON: '+ converter.NumberToLetters(this.payment.total);


  this.payment.cash_payment = this.payment.total;
  this.payment.card_payment = (0).toFixed(2);
  this.payment.web_way_payment = (0).toFixed(2);

}

function PaymentMethod(type) {
  if (type == 'card') {
    let total = parseFloat(this.payment.total) - parseFloat(this.payment.card_payment);
    if (total >= 0) {
      this.payment.cash_payment = total.toFixed(2);
      this.payment.card_payment = parseFloat(this.payment.card_payment).toFixed(2);
      this.payment.web_way_payment = (0).toFixed(2);
    }else{
      this.payment.cash_payment = parseFloat(this.payment.total).toFixed(2);
      this.payment.card_payment = (0).toFixed(2);
      this.payment.web_way_payment = (0).toFixed(2);
    }
  }

  if (type == 'web') {
    let total = parseFloat(this.payment.total) - parseFloat(this.payment.web_way_payment);
    if (total >= 0) {
      this.payment.cash_payment = total.toFixed(2);
      this.payment.card_payment = (0).toFixed(2);
      this.payment.web_way_payment = parseFloat(this.payment.web_way_payment).toFixed(2);
    }else{
      this.payment.cash_payment = parseFloat(this.payment.total).toFixed(2);
      this.payment.card_payment = (0).toFixed(2);
      this.payment.web_way_payment = (0).toFixed(2);
    }
  }

  
}

function ViewRoomControl() {
  let me = this;
  let url = this.url_base + "room-control/view/" + this.id_room_control;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {

        me.client = {id : response.data.result.id_client, full_name: response.data.result.client_name + ' - ' + response.data.result.client_document_number};

        me.room_control.client_name = response.data.result.client_name;
        me.room_control.client_document_number = response.data.result.client_document_number;
        me.room_control.id_client = response.data.result.id_client;
        me.room_control.id_room = response.data.result.id_room;
        me.room_control.id_type_room = response.data.result.id_type_room;
        me.room_control.id_room_control = response.data.result.id_room_control;
        me.room_control.id_user = response.data.result.id_user;
        me.room_control.price = response.data.result.price;
        me.room_control.quantity = response.data.result.quantity;
        me.room_control.start_date = response.data.result.start_date;
        me.room_control.final_date = response.data.result.final_date;
        me.room_control.date = moment(response.data.result.date).local().format("YYYY-MM-DD");
        me.room_control.hour = response.data.result.hour;
        me.room_control.room_price = response.data.result.room_price;

        me.payment.id_client = response.data.result.id_client;
        me.payment.id_room_control = response.data.result.id_room_control;
    
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })

}


//modal new cliente
function ShowModalClient() {
  EventBus.$emit('ModalClient');
}

//agregar control habitacion
function AddPayment() {

  // this.Validate();
  if (this.validate) {
    return false;
  }

  this.payment.id_user = this.id_user;
  this.payment.detail = this.room_control_detail;

  let me = this;
  let url = this.url_base + "payment/add";
  let data = this.payment;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        Swal.fire("Sistema", "Se ha realizado el pago ", "success");
        me.modal_payment = false;
        me.mLoadListRoomControl();
      } else if  (response.data.status == 400) {
        Swal.fire("Sistema", response.data.message, "error");
      }else{
          Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}



function Validate() {

  this.validate = false;


  this.errors.final_date = this.room_control.start_date.length == 0 ? true : false;
  this.errors.price = this.room_control.start_date.length == 0 ? true : false;
  this.errors.quantity = this.room_control.start_date.length == 0 ? true : false;
  this.errors.room_price = this.room_control.start_date.length == 0 ? true : false;

  if (this.errors.final_date) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.price) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.quantity) { this.validate = true; return false;}else{ this.validate = false; }
  if (this.errors.room_price) { this.validate = true; return false;}else{ this.validate = false; }

}


</script>