<template>
  <div>
    <b-modal size="md" hide-footer v-model="modal_minibar" class="w-100" title="FRIGROBAR">
      <b-row>
            <b-col sm="7" md="9">
              <b-form-group>
                <label class="control-label">Producto: </label>
                <v-select placeholder="Seleccione un producto" class="w-100" :filterable="false" label="name" v-model="product" @search="SearchProducts" :options="products"></v-select>
                <small v-if="errors.id_product" class="form-text text-danger" >Seleccione un producto</small>
              </b-form-group>
            </b-col>
            <b-col sm="3" md="2">
              <b-form-group>
                <label class="control-label">Cantidad: </label>
                <b-form-input
                  v-model="room_control_detail.quantity"
                  class="form-control"
                ></b-form-input>
                <small v-if="errors.quantity" class="form-text text-danger" >Ingrese una cantidad</small>
              </b-form-group>
            </b-col>
            <b-col sm="2" md="1">
              <b-form-group>
                <label class="control-label">.</label>
                <button @click="AddDetailRoomControl" class="btn btn-info form-control"><i class="fas fa-cart-plus"></i></button>
              </b-form-group>
            </b-col>
      </b-row>


      <b-row>
        <b-col md="12">
          <b-alert
            class="col-md-12"
            :show="alert.dismissCountDown"
            dismissible
            :variant="alert.type"
            @dismissed="alert.dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >
            {{alert.message}}
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
            <b-col md="12">
                <div class="table-responsive mt-3">
                  <table class="table table-striped table-hover table-bordered table-lg mt-lg mb-0">
                    <thead>
                      <tr>
                        <th width="5%" class="text-center">#</th>
                        <th width="10%" class="text-center">Codigo</th>
                        <th width="45%" class="text-center">Nombre</th>
                        <th width="12%" class="text-center">Cantidad</th>
                        <th width="13%" class="text-center">P. Unit</th>
                        <th width="8%" class="text-center">P. Total</th>
                        <th width="7%" class="text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody v-for="(item, it) in room_control_minibar" :key="it">
                      <tr>
                        <td class="text-center">{{ it + 1 }}</td>
                        <td class="text-left">{{ item.code }}</td>
                        <td class="text-left">{{ item.name }}</td>
                        <td class="text-left">
                          <input type="number" @change="UpdateDetailRoomControl(item.id_room_control_detail)" class="form-control text-center" v-model="item.quantity">
                        </td>
                        <td class="text-left">
                          <input type="number" @change="UpdateDetailRoomControl(item.id_room_control_detail)" class="form-control text-right" v-model="item.unit_price">
                        </td>
                        <td class="text-right">{{ item.total_price }}</td>
                        <td class="text-center">
                          <b-button type="button" @click="DeleteDetailRoomControl(item.id_room_control_detail)" class="btn btn-sm btn-danger"><i class="fas fa-trash-alt"></i></b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </b-col>
      </b-row>




    </b-modal>
  </div>
</template>
<style>


.table th, .table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    vertical-align: middle;
}

</style>



<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";

export default {
  name: "ModalMinibar",
  components:{
    vSelect,
  },
  data() {
    return {
        // module
        module:'RoomControl',
        role:2,
        ///alerta
        alert:{
          dismissSecs: 5,
          dismissCountDown: 0,
          showDismissibleAlert: false,
          type_alert: '',
          message:'',
        },
        
        // data
        modal_minibar:false,
        id_room_control: 0,
        room_control_minibar:[],
        room_control_detail: {
          id_room_control_detail :0,
          id_room_Control :0,
          id_product :0,
          type :'Minibar',
          quantity:1,
          unit_price:0,
          total_price:0,
        },
        product:null,
        products:[],

        // errors
        errors:{
          id_product:false,
          quantity:false,

        },
        validate:false,
        
    };
  },
  created (){
    
  },
  mounted () {
      EventBus.$on('ModalMinibarShow', (id_room_control) => {
        this.modal_minibar = true;
        this.id_room_control = id_room_control;
        this.ListRoomControlMinibar();
      });
      
    
  },
  methods: {
      SearchProducts,
      AddDetailRoomControl,
      UpdateDetailRoomControl,
      DeleteDetailRoomControl,
      ListRoomControlMinibar,
      Validate,
      ...mapActions('RoomControl',['mLoadListRoomControl']),
      countDownChanged(dismissCountDown) {
        this.alert.dismissCountDown = dismissCountDown
      },
      showAlertProducts(type,message) {
        this.alert.type = type;
        this.alert.message = message;
        this.alert.dismissCountDown = this.alert.dismissSecs
      }
  },
  computed: {
    ...mapState(["url_base"]),
    ...mapState('RoomControl',['room_control']),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function SearchProducts(search, loading) {
  let me = this;
  let url = this.url_base + "search-products-stock/" + search;
  if (search !== "") {
    loading(true);
    axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      if (response.data.status == 200) {
        me.products = response.data.result;
        loading(false);
      }else{
        loading(false);
      }
          
    })
  }
}

function ListRoomControlMinibar() {

  let me = this;
  let url = this.url_base + "room-control-detail/list/"+this.id_room_control+"/Minibar";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room_control_minibar = response.data.result;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function AddDetailRoomControl() {
  
  this.Validate('Add');
  if (this.validate) {
    return false;
  }


  this.room_control_detail.id_room_control = this.id_room_control;
  this.room_control_detail.id_product = this.product.id;
  this.room_control_detail.unit_price = this.product.sale_price;
  this.room_control_detail.total_price = parseFloat(this.room_control_detail.unit_price) *  parseFloat(this.room_control_detail.quantity);

  let me = this;
  let url = this.url_base + "room-control-detail/add";
  let data = this.room_control_detail;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.ListRoomControlMinibar();
        me.mLoadListRoomControl();
        me.product = null;
        me.showAlertProducts("success","Se ha registrado el producto");
      }else if(response.data.status == 400){
        me.showAlertProducts("warning","No hay stock suficiente para registrar este producto");
      }else{
        me.showAlertProducts("error","A Ocurrido un error");
      }
    })
  

}

function UpdateDetailRoomControl(id_room_control_detail) {
    let room_control_detail = null;
    for (var i = 0; i < this.room_control_minibar.length; i++) {
      if (this.room_control_minibar[i].id_room_control_detail == id_room_control_detail) {
        room_control_detail = this.room_control_minibar[i];
        break;
      }
    }
    room_control_detail.quantity = room_control_detail.quantity.length == 0 ? 1:room_control_detail.quantity;
    room_control_detail.unit_price = room_control_detail.unit_price.length == 0 ? 0:room_control_detail.unit_price;

    let me = this;
    let url = this.url_base + "room-control-detail/edit";
    axios({
      method: "PUT",
      url: url,
      data:room_control_detail,
      headers: {
        "Content-Type": "application/json",
        token: this.token,
        module: this.module,
        role: this.role,
      },
    })
      .then(function (response) {
        if (response.data.status == 200) {
          me.mLoadListRoomControl();
          for (var i = 0; i < me.room_control_minibar.length; i++) {
            if (me.room_control_minibar[i].id_room_control_detail == id_room_control_detail) {
              me.room_control_minibar[i].quantity = response.data.result.quantity;
              me.room_control_minibar[i].unit_price = response.data.result.unit_price;
              me.room_control_minibar[i].total_price = response.data.result.total_price;
              break;
            }
          }
          me.showAlertProducts("success","Se ha actualizado el producto");
        }else if(response.data.status == 400) {
          me.mLoadListRoomControl();
          for (var i = 0; i < me.room_control_minibar.length; i++) {
            if (me.room_control_minibar[i].id_room_control_detail == id_room_control_detail) {
              me.room_control_minibar[i].quantity = response.data.result.quantity;
              me.room_control_minibar[i].unit_price = response.data.result.unit_price;
              me.room_control_minibar[i].total_price = response.data.result.total_price;
              break;
            }
          }
          me.showAlertProducts("danger",response.data.message);
        }else{
          me.showAlertProducts("danger","A Ocurrido un error");
        }
      })

    console.log(room_control_detail);
}
function DeleteDetailRoomControl(id_room_control_detail) {

  let me = this;
  let url = this.url_base + "room-control-detail/delete/"+id_room_control_detail;
  axios({
    method: "delete",
    url: url,
    headers: {
      "Content-Type": "application/json",
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.mLoadListRoomControl();
        me.ListRoomControlMinibar();
        me.showAlertProducts("success","Se ha eliminado el producto");
      }else{
        me.showAlertProducts("success","A ocurrido un error");
      }
    })
  
}
function Validate(type) {
  this.validate = false;
  if (type == 'Add') {
    this.errors.id_product = this.product == null || this.product == {} ? true : false;
    this.errors.quantity = this.room_control_detail.quantity.length == 0 ? true : false;

    if (this.errors.id_product) { this.validate = true; return false;}else{ this.validate = false; }
    if (this.errors.quantity) { this.validate = true; return false;}else{ this.validate = false; }
  }
}
</script>
